import { Maybe } from '@designage/gql';
import { IZoneResolution } from '@desquare/interfaces';
import { AssetType } from '@designage/gql';

export function getOptimizedUrl(
  assetType: AssetType,
  uri: Maybe<string>,
  resolution?: Maybe<IZoneResolution>,
  resizeCropMethod?: Maybe<string>
) {
  switch (assetType) {
    case AssetType.Video:
      let arcVideoParams = '';
      if (resolution?.width) {
        arcVideoParams = `w_${resolution?.width},`;
      }
      if (resolution?.height) {
        arcVideoParams += `h_${resolution?.height},`;
      }
      if (resizeCropMethod) {
        arcVideoParams += resizeCropMethod;
      }
      if (uri) {
        const alteredUrlParams = `upload/${arcVideoParams}/`;
        const optimizedUrl = uri.replace('upload/', alteredUrlParams);
        return optimizedUrl;
      } else {
        return '';
      }
      break;

    case AssetType.Image:
      let arcImageParams = '';
      if (resolution?.width) {
        arcImageParams = `w_${resolution?.width},`;
      }
      if (resolution?.height) {
        arcImageParams += `h_${resolution?.height},`;
      }
      if (resizeCropMethod) {
        arcImageParams += resizeCropMethod.concat(',');
      }
      if (uri) {
        const alteredUrlParams = `upload/${arcImageParams}q_auto,f_auto/`;
        const optimizedUrl = uri.replace('upload/', alteredUrlParams);
        return optimizedUrl;
      } else {
        return '';
      }
      break;

    default:
      if (uri) {
        return uri;
      } else {
        return '';
      }
      break;
  }
}

/**
 * This function converts a cloudinary url to change the extension part into .webp example:
 * - from https://res.cloudinary.com/designage/video/.../aavo7nnzgorrsnjwwc13.mp4
 * - changed to https://res.cloudinary.com/designage/video/.../aavo7nnzgorrsnjwwc13.webp
 * - notice the example is supposedly a video cloudinary API handles the transformation logic
 *   all we need to do is pass the url
 *
 * @param url - Cloudinary URL
 * @returns
 */
export function urlToWebp(url: string) {
  // regex for matching the extension part of the url:
  // regex 1: /\.(\w+)$/
  // regex 2: /\.([a-zA-Z0-9]+)$/
  // note: the slashes (/) indicates the start or end
  // of a regular expression

  return url.replace(new RegExp(/\.(\w+)$/), '.webp');
}
